import React, { useEffect, useContext, useState } from 'react'
import { Box, Text } from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import Divider from '@solid-ui-components/Divider'
import { ModalContext } from '@solid-ui-components/Modal'
import yesilGoz from '../../assets/robot/pembegear.gif'
import Reveal from '@solid-ui-components/Reveal'
import Daktilo from '@solid-ui-components/Daktilo'


const Robot = ({ ...props }) => {
  const { setActiveModal } = useContext(ModalContext)
  const [lck, setLck] = useState(null);


  const {
    tip,
    yazi,
    islemId
    // sureler
  } = props

  const [msjIndex, setmsjIndex] = useState(0)


  let tmOut = null;

  useEffect(() => {
    if (tmOut != null) {
      setmsjIndex(5000);
      clearTimeout(tmOut);
      //console.log(islemId);
    }
    if (msjIndex <= yazi.split(",").length) {
      tmOut = setTimeout(() => {
       // console.log(yazi.split(",")[msjIndex] + msjIndex);
        setmsjIndex(msjIndex + 1);
      }, msjIndex == yazi.split(",").length ? 10000 : yazi.split(",")[msjIndex].length * 110);
    }
  }, [msjIndex, islemId]);

  return (
    <>
      {yazi.split(",").map((splText, ind) => {
        return (
          <div key={"rbt" + ind}>
            {msjIndex == ind && <div className={'dv-robot ' + islemId}>
              <Reveal effect={'floatFaster'} delay={1.6}>
                <div className={"bubble bubble-bottom-left bb-" + tip ?? "info"} >
                  <Daktilo text={yazi.split(",")[ind]} delay={yazi.split(",")[ind].length > 25 ? 25 : yazi.split(",")[ind].length - 5} />
                </div>
              </Reveal>
              <img src={yesilGoz} alt="Kt-Asistan" style={{ width: "100px", objectFit: "contain" }} />
            </div>
            }
          </div>
        )
      })
      }
      {msjIndex == yazi.split(",").length && <div className='dv-robot'>
        <Reveal effect={'floatFaster'} delay={1.6}>
          <div className={"bubble bubble-bottom-left bb-" + tip ?? "info"} >
            <Daktilo text={"Sonra Görüşürüz..."} delay={15} />
          </div>
        </Reveal>
        <img src={yesilGoz} alt="Kt-Asistan" style={{ width: "100px", objectFit: "contain" }} />
      </div>
      }
    </>
  )
}

export default WithDefaultContent(Robot)
