import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import TeklifTextField from '@solid-ui-components/ContentForm/TeklifTextField'
import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'

import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import ReactModal from 'react-modal'
import { FaRegStar, FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import Slider from '@solid-ui-blocks/Slider/Block01'
import loadingGif from '../../../assets/model-cizim-kutu-tasarimi2.gif'
import parse from 'html-react-parser'
import { setKtTokenSifre, getKtLogin, logOut, getKtToken } from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import btnloading from '../../../assets/btnloading.gif'

import loadsBeyazGif from '../../../assets/loadsBeyaz.gif'
import indirPng from '../../../assets/online-paskage-pdf-die-cut.png'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import { ModalContext } from '@solid-ui-components/Modal'
// import indirmeCss from './_bicakizi.css' //style={indirmeCss}
import Tabs from '@solid-ui-components/Tabs'
// import { Tab } from 'react-tabs'
import { TabsContext } from '@solid-ui-components/Tabs'
import { AiOutlineCloseCircle, AiFillCloseSquare, AiOutlineDelete } from 'react-icons/ai'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion'
import Icon from '@solid-ui-components/ContentIcon'
import Reveal from '@solid-ui-components/Reveal'
import toast, { Toaster } from 'react-hot-toast'
// import { useDispatch, useSelector } from 'react-redux'
// import MusteriEdit from '@solid-ui-blocks/Maliyet/Musteri'
// import LevhaKaliteEdit from '@solid-ui-blocks/Maliyet/Levha'
// import OfsetMakineEdit from '../OfsetMakine/OfsetMakine'
// import RenkEdit from '@solid-ui-blocks/Maliyet/Renk'
// import SivamaMakineEdit from '@solid-ui-blocks/Maliyet/SivamaMakine'
// import SelefonMakineEdit from '../SelefonMakine/SelefonMakine'
// import SelefonCinsiEdit from '../SelefonCinsi/SelefonCinsi'
// import LakCesidiEdit from '../LakCesidi/LakCesidi'
// import YaldizCesidiEdit from '../YaldizCesidi/YaldizCesidi'
// import KesimaMakineEdit from '@solid-ui-blocks/Maliyet/KesimMakine'
// import YapistirmaTuruEdit from '@solid-ui-blocks/Maliyet/YapistirmaTuru'
// import YapistirmaMakineEdit from '@solid-ui-blocks/Maliyet/YapistirmaMakine'
// import VadeEdit from '@solid-ui-blocks/Maliyet/Vade'
import Robot from '@solid-ui-blocks/Robot'
import Daktilo from '@solid-ui-components/Daktilo'

import FileEdit from '@solid-ui-blocks/Maliyet/FileUpl'
// import config from '../../../../../../site/src/core/config'
import config from '../../../../../../site/src/core/config'

const styles = {
    btnResimDegistir: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "white",
        border: "0.8px dotted red",
        fontSize: "0.87rem",

    },
    btnIslemler: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "#87f9bb",
        border: "0.8px dotted #204c07",
        fontSize: "0.87rem",
        color: '#204c07'
    },
    btnIslemSil: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "#FF6666",
        border: "0.8px dotted #FF8989",
        fontSize: "0.87rem",
        color: 'white'
    }
}

const SiparisFrm = ({ content: { SiparisId }, ...props }) => {

    const formId = "frmSiparis"
    const { handleSubmit, submitting, success } = useForm()
    const { formValues, setFormValues } = useContext(FormContext)
    const { setActiveModal } = useContext(ModalContext)
    const [ldg, setLdg] = useState(false);
    const [btnLdg, setbtnLdg] = useState(true);


    const [fileTip, setfileTip] = useState(0);

    const [siparis, setsiparis] = useState(null);
    const [teklif, setTeklif] = useState(null);

    const [prm, setparametre] = useState(null);


    const [firtProcess, setfirtProcess] = useState("1");

    useEffect(async () => {
        if (typeof window !== 'undefined' && firtProcess == "1") {
            const ktLoginMiii = await getKtLogin();
            if (ktLoginMiii == true) {
                setfirtProcess("2");
                GetSiparis();
                GetPrm();
            }
            else {
                window.location.assign("/");
            }
        }
        setLdg(true);
    }, [firtProcess]);


    async function GetSiparis() {

        httpServiceToken.post("Locked/GetSiparis", { params: SiparisId }).then((res) => {
            // console.log(res);
            if (res.DogruMu) {
                setsiparis(res.Data);
                setTeklif(res.Data.Teklif);
            } else {

            }
        }).catch((err) => {
            console.log(err);
        });

    }
    async function GetPrm() {


        httpServiceToken.post("Locked/GetPrm", { params: "" }).then((res) => {

            if (res.DogruMu) {
                setparametre(res.Data);
            } else {
                setparametre(null);
            }
        }).catch((err) => {
            //console.log(err);
        });

    }


    const [popFile, setpopFile] = useState(false)
    const handleFilePopup = () => {
        setpopFile(!popFile);
        setfileTip(0);
    }

    const {
        token
    } = getKtToken()


    const [lnk, setLnk] = useState(true);

    return (
        <Container>
            <Toaster />
            {ldg == false ? <div className='dv-l'>
                <Divider space="3" />
                <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
                <Divider space="5" />
            </div> :
                <form

                    method='POST'
                >
                    <Box>

                        <Box sx={{ textAlign: `center` }} >
                            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
                                <Box sx={{
                                    flex: [`100%`, null, null, 1],
                                    width: '100%',
                                    px: [2, 2],
                                    py: [0, 1]
                                }}>
                                    <div>
                                        {siparis && teklif && prm && <Box sx={{ p: '15px' }}>
                                            <table style={{
                                                width: '100%',
                                            }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{
                                                            width: '25%',
                                                        }} >
                                                            <Box
                                                                sx={styles.btnIslemSil}
                                                                onClick=
                                                                {e => {
                                                                    httpServiceToken.post("Locked/SiparisSil", { params: siparis.Id }).then((res) => {
                                                                        if (res.DogruMu) {
                                                                            toast.success(res.Mesaj, {
                                                                                duration: 5000,
                                                                                position: 'top-left'
                                                                            });
                                                                            window.location.assign("/ambalaj-kutu-maliyet-hesaplama?T=" + res.Data)
                                                                        } else {
                                                                            toast.error(res.Mesaj, {
                                                                                duration: 5000,
                                                                                position: 'top-left'
                                                                            });
                                                                        }
                                                                    }).catch((err) => {

                                                                    });
                                                                }}>
                                                                Siparişi Sil Teklife Dön
                                                            </Box>
                                                            Teklif Aşamasına Geri Alabilirsiniz
                                                        </td>
                                                        <td style={{
                                                            width: '25%',
                                                        }} >
                                                            <Box
                                                                sx={styles.btnIslemler}
                                                                onClick=
                                                                {e => {
                                                                    document.getElementById("ifRam").removeAttribute("src");
                                                                    document.getElementById("ifRam").setAttribute("src", config.serviceBaseAddress + "Siparis.aspx?T=" + token + "&i=" + siparis.Id + "&dw=e");
                                                                }}>
                                                                Sipariş Formunu İndir
                                                            </Box>
                                                            Pdf Formatta İndirebilirsiniz
                                                        </td>
                                                        <td style={{
                                                            width: '25%',
                                                        }} >
                                                            <Box
                                                                sx={styles.btnIslemler}
                                                                onClick=
                                                                {e => {
                                                                    httpServiceToken.post("Locked/SatinAlmaBaslat", { params: siparis.Id }).then((res) => {
                                                                        if (res.DogruMu) {
                                                                            toast.success(res.Mesaj, {
                                                                                duration: 5000,
                                                                                position: 'top-left'
                                                                            });
                                                                            if (siparis.SatinAlmaDurum == 10 || siparis.SatinAlmaDurum == 20) {
                                                                                window.location.assign("/satin-alma?S=" + res.Data);
                                                                            } else if (siparis.SatinAlmaDurum == 30) {
                                                                                window.location.assign("/satin-alma?B=" + res.Data);
                                                                            }
                                                                            if (siparis.SatinAlmaDurum == 40) {
                                                                                window.location.assign("/satin-alma?T=" + res.Data);
                                                                            }
                                                                        } else {
                                                                            toast.error(res.Mesaj, {
                                                                                duration: 5000,
                                                                                position: 'top-left'
                                                                            });
                                                                        }
                                                                    }).catch((err) => {

                                                                    });
                                                                }}>
                                                                {siparis.SatinAlmaDurum == 10 ? "Satın Alma Siparişini Başlat" : siparis.SatinAlmaDurum == 20 ? "Satın Alma Siparişi Geç" : "Satın Alma Formuna Git"}
                                                            </Box>


                                                            Durum: {siparis.SatinAlmaDurumText}
                                                            {siparis.SatinAlmaDurum == 30 && <Box
                                                                sx={styles.btnIslemler}
                                                                onClick=
                                                                {e => {
                                                                    if (siparis.SatinAlmaDurum == 30) {
                                                                        httpServiceToken.post("Locked/SatinAlmaTeslimAlindi", { params: siparis.Id }).then((res) => {
                                                                            if (res.DogruMu) {
                                                                                toast.success(res.Mesaj, {
                                                                                    duration: 5000,
                                                                                    position: 'top-left'
                                                                                });
                                                                                window.location.assign("/satin-alma?T=" + res.Data)
                                                                            } else {
                                                                                toast.error(res.Mesaj, {
                                                                                    duration: 5000,
                                                                                    position: 'top-left'
                                                                                });
                                                                            }
                                                                        }).catch((err) => {

                                                                        });
                                                                    } else {
                                                                        toast.error("Satın alma siparişi geçilmeden teslim alınamaz", {
                                                                            duration: 5000,
                                                                            position: 'top-left'
                                                                        });
                                                                    }

                                                                }}>
                                                                Tamamını Teslim Alındı Yap
                                                            </Box>
                                                            }
                                                        </td>
                                                        <td style={{
                                                            width: '25%',
                                                        }} >
                                                            <Box
                                                                sx={styles.btnIslemler}
                                                                onClick=
                                                                {e => {
                                                                    
                                                                    httpServiceToken.post("Locked/UretimBaslat", { params: siparis.Id }).then((res) => {
                                                                        if (res.DogruMu) {
                                                                            toast.success(res.Mesaj, {
                                                                                duration: 5000,
                                                                                position: 'top-left'
                                                                            });

                                                                            if (siparis.IsDurum == 10 || siparis.IsDurum == 20) {
                                                                                window.location.assign("/uretim-takip?B=" + res.Data);
                                                                            } else if (siparis.IsDurum == 100) {
                                                                                window.location.assign("/uretim-takip?U=" + res.Data);
                                                                            }
                                                                            if (siparis.IsDurum == 1000) {
                                                                                window.location.assign("/uretim-takip?T=" + res.Data);
                                                                            }
                                                                        } else {
                                                                            toast.error(res.Mesaj, {
                                                                                duration: 5000,
                                                                                position: 'top-left'
                                                                            });
                                                                        }
                                                                    }).catch((err) => {

                                                                    });
                                                                }}>
                                                                {siparis.IsDurum == 10 ? "İş Emrini Oluştur" :siparis.IsDurum == 20 ? "İş Emri Formuna Git": siparis.IsDurum == 100 ? "Ürünleri Teslim Et" : "İş Emri Formuna Git (Teslim)"}
                                                            </Box>
                                                            Üretim: {siparis.IsDurumText}
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table style={{ width: '100%', backgroundColor: 'white', padding: "15px" }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={3} >  <b>SİPARİŞ FORMU</b> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '20%' }}>


                                                                            <img
                                                                                src={teklif.TeklifLogo}
                                                                                style={{ maxWidth: '90%', height: '170px', objectFit: 'contain' }}

                                                                            />


                                                                        </td>
                                                                        <td style={{ width: '10%' }}>

                                                                        </td>
                                                                        <td style={{ width: '70%' }}>
                                                                            <table style={{ width: '100%', paddingRight: '20px', paddingBottom: '10px' }}>
                                                                                <tbody>
                                                                                    <tr >
                                                                                        <td style={{ width: '50%', fontSize: '12px', textAlign: 'left', paddingBottom: '10px' }}>

                                                                                            ÜNVAN :<b> {teklif.MustResmiAdi}</b>
                                                                                            <br />
                                                                                            İŞ ADI: <b>{teklif.IsAdi}</b>
                                                                                            <br />
                                                                                            ÜRÜN SAYISI: <b>{teklif.Adet}</b>
                                                                                            <br />
                                                                                        </td>
                                                                                        <td style={{ width: '40%', textAlign: 'right', fontSize: '12px' }}>
                                                                                            SİPARİŞ NO :<b style={{ fontSize: '16px' }} > {siparis.SiparisNo}</b>
                                                                                            <br />
                                                                                            SİPARİŞ TARİHİ :<b> {siparis.SiparisTarihiText} </b>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr style={{ textAlign: 'center' }}>
                                                                                        <td colSpan={2}  >
                                                                                            {teklif.FormIcerik.length > 0 && <table border="1px" style={{ borderColor: "#fdfdfd", borderCollapse: 'collapse' }}>
                                                                                                <tbody>

                                                                                                    <tr>
                                                                                                        {teklif.FormIcerik.map(
                                                                                                            ({ Head, Info }, ind2) => (
                                                                                                                <td  key={`item-${ind2}`} style={{ fontSize: '12px', padding: '0.2rem' }}>{Head}</td>
                                                                                                            ))}

                                                                                                    </tr>


                                                                                                    <tr>
                                                                                                        {teklif.FormIcerik.map(
                                                                                                            ({ Head, Info }, ind2) => (
                                                                                                                <td key={`item-${ind2}`} style={{ fontSize: '11px', padding: '0.5rem', fontWeight: "bold" }}>{Info}</td>
                                                                                                            ))}

                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table style={{ width: '100%', }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '50%' }}>
                                                                            <div
                                                                                style={{

                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={teklif.TeklifResimSol}
                                                                                    style={{ maxWidth: '100%', height: '350px', objectFit: 'contain' }}
                                                                                />


                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: '50%' }}>
                                                                            <div
                                                                                style={{

                                                                                    textAlign: "center"
                                                                                }} >
                                                                                <img
                                                                                    src={teklif.TeklifResimSag}
                                                                                    style={{ maxWidth: '100%', height: '350px', objectFit: 'contain' }}
                                                                                />

                                                                            </div>
                                                                        </td>
                                                                    </tr >
                                                                </tbody>
                                                            </table >
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={3} style={{
                                                                            width: '100%',
                                                                            paddingLeft: "0px",
                                                                            textAlign: 'left',
                                                                            fontSize: '12px',
                                                                            fontFamily: 'helvetica, arial, sans- serif',
                                                                        }}>
                                                                            TESLİMAT ADRESİ : {teklif.TeslimatAdresi}
                                                                        </td>
                                                                        <td rowSpan={3} style={{ width: '25%', }}>

                                                                            <table
                                                                                style={{
                                                                                    width: '90%',
                                                                                    borderCollapse: 'collapse',
                                                                                    textAlign: 'right',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '13px'
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Birim Fiyat :</td>
                                                                                        <td> {teklif.BirimSatisFiyati.toFixed(2)} ₺</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Toplam :</td>
                                                                                        <td> {teklif.TeklifTutari.toFixed(2)} ₺</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>KDV({teklif.KdvOrani}) :</td>
                                                                                        <td>{teklif.KDVTutari.toFixed(2)} ₺</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Genel Toplam :</td>
                                                                                        <td>{teklif.GenelToplam.toFixed(2)} ₺</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td >
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}>
                                                                            <table border="1px" style={{
                                                                                width: '100%',
                                                                                padding: "10px",
                                                                                textAlign: 'left',
                                                                                fontSize: '12px',
                                                                                fontFamily: 'helvetica, arial, sans- serif',
                                                                                borderColor: "#fdfdfd",
                                                                                borderCollapse: 'collapse'
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr >
                                                                                        <td style={{ padding: '0.3rem' }}>SİPARİŞ NOTU :{teklif.TeklifNot}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                width: '30%',
                                                                                textAlign: 'left',
                                                                                padding: '10px',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    fontVariantCaps: 'normal',
                                                                                    fontWeight: '200px',
                                                                                    fontSize: '8px',
                                                                                    color: 'rgb(105, 105, 105)'
                                                                                }}
                                                                            >
                                                                                <br />
                                                                                <h2>GENEL SİPARİŞ KOŞULLARI:</h2>
                                                                                <dl>
                                                                                    {prm.SiparisKosullari1 && <dt>
                                                                                        {prm.SiparisKosullari1}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari2 && <dt>
                                                                                        {prm.SiparisKosullari2}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari3 && <dt>
                                                                                        {prm.SiparisKosullari3}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari4 && <dt>
                                                                                        {prm.SiparisKosullari4}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari5 && <dt>
                                                                                        {prm.SiparisKosullari5}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari6 && <dt>
                                                                                        {prm.SiparisKosullari6}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari1 && <dt>
                                                                                        {prm.SiparisKosullari1}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari7 && <dt>
                                                                                        {prm.SiparisKosullari7}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari8 && <dt>
                                                                                        {prm.SiparisKosullari8}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari9 && <dt>
                                                                                        {prm.SiparisKosullari9}
                                                                                    </dt>}
                                                                                    {prm.SiparisKosullari10 && <dt>
                                                                                        {prm.SiparisKosullari10}
                                                                                    </dt>}
                                                                                </dl>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ minWidth: '200px', width: '15%', textAlign: 'center' }}>
                                                                            <div style={{ fontWeight: 300, fontSize: '12px' }}>
                                                                                <table border="1px" style={{ minWidth: '200px', borderCollapse: 'collapse', borderColor: "#fdfdfd" }}>
                                                                                    <tbody>
                                                                                        <tr >
                                                                                            <td >
                                                                                                <span style={{ fontSize: '11px' }}>
                                                                                                    Sipariş Alan<br />
                                                                                                    <img
                                                                                                        src={teklif.KaseResim}
                                                                                                        style={{ maxWidth: '90%', height: '100px', objectFit: 'contain' }}
                                                                                                    />

                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td >
                                                                                                <span style={{ fontSize: '11px', padding: '10px' }}>
                                                                                                    Sipariş Veren<br />
                                                                                                    <img
                                                                                                        src="https://kututasarimi.com/teklifverenbos.png?2"
                                                                                                        style={{ maxWidth: '90%', height: '100px', objectFit: 'contain' }}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '30%',
                                                                                padding: '5px 50px',
                                                                                fontSize: '15px'
                                                                            }}
                                                                        >
                                                                            <table style={{
                                                                                textAlign: 'left',
                                                                                borderColor: "#fdfdfd",
                                                                                fontSize: '12px',
                                                                                fontFamily: 'helvetica, arial, sans- serif',
                                                                                borderCollapse: 'collapse',
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ padding: '0.2rem' }}>Ödeme :</td>
                                                                                        <td style={{ padding: '0.3rem' }}>{teklif.VadeText}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ padding: '0.2rem' }}>Üretim Süresi :</td>
                                                                                        <td style={{ padding: '0.3rem' }}>{teklif.UretimSuresi} Gün</td>
                                                                                    </tr>
                                                                                    {teklif.MustIrtibatKisi && <tr>
                                                                                        <td style={{ padding: '0.2rem' }}>İrtibat :</td>
                                                                                        <td style={{ padding: '0.3rem' }}>{teklif.MustIrtibatKisi}</td>
                                                                                    </tr>
                                                                                    }
                                                                                    {teklif.MusteriTel && <tr>
                                                                                        <td style={{ padding: '0.2rem' }}>İrtibat Tel :</td>
                                                                                        <td style={{ padding: '0.3rem' }}>{teklif.MusteriTel}</td>
                                                                                    </tr>
                                                                                    }
                                                                                    {teklif.MusteriMail && <tr>
                                                                                        <td style={{ padding: '0.2rem' }}>İrtibat Mail :</td>
                                                                                        <td style={{ padding: '0.3rem' }}>{teklif.MusteriMail}</td>
                                                                                    </tr>

                                                                                    }
                                                                                    {teklif.Onaylayan && <tr>
                                                                                        <td style={{ padding: '0.2rem' }}>Teklifi Onaylayan :</td>
                                                                                        <td style={{ padding: '0.3rem' }}>{teklif.Onaylayan}</td>
                                                                                    </tr>

                                                                                    }
                                                                                </tbody>
                                                                            </table >
                                                                        </td >
                                                                    </tr >
                                                                </tbody>
                                                            </table >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div id="ifRa" style={{ display: "none", height: "0px" }}>
                                                <iframe id="ifRam" ></iframe>
                                            </div>

                                            {/* {fileTip == 1 && <FileEdit
                                                addEventOpen={popFile}
                                                handlePopup={handleFilePopup}
                                                detayName={"Teklif Logo Yükleme"}
                                                mesaj={"Bundan sonraki tekliflerinize eklememiz için logo yükleyiniz"}
                                                listRefreshEvent={getTeklif}
                                                lnk={lnk}
                                            />
                                            }
                                            {fileTip == 1 && <FileEdit
                                                addEventOpen={popFile}
                                                handlePopup={handleFilePopup}
                                                detayName={"Teklif Logo Yükleme"}
                                                mesaj={"Bundan sonraki tekliflerinize eklememiz için logo yükleyiniz"}
                                                listRefreshEvent={getTeklif}
                                                lnk={lnk}
                                            />
                                            }
                                            {fileTip == 2 && <FileEdit
                                                addEventOpen={popFile}
                                                handlePopup={handleFilePopup}
                                                detayName={"Kaşe Resmi Yükleme"}
                                                mesaj={"Bundan sonraki tekliflerinize eklememiz için kaşe yükleyiniz"}
                                                listRefreshEvent={getTeklif}
                                                lnk={lnk}
                                            />
                                            }
                                            {fileTip == 3 && <FileEdit
                                                addEventOpen={popFile}
                                                handlePopup={handleFilePopup}
                                                detayName={"Ambalaj Çizim Detayı Yükleme"}
                                                mesaj={"Ambalaj çiminin ölçülerini gösteren bir resim yükleyiniz"}
                                                listRefreshEvent={getTeklif}
                                                lnk={lnk}
                                            />
                                            }
                                            {fileTip == 4 && <FileEdit
                                                addEventOpen={popFile}
                                                handlePopup={handleFilePopup}
                                                detayName={"Ambalaj Tasarım Detayı Yükleme"}
                                                mesaj={"Ambalaj tasarım detaylarını gösteren bir resim yükleyiniz"}
                                                listRefreshEvent={getTeklif}
                                                lnk={lnk}
                                            />
                                            } */}
                                        </Box>
                                        }
                                    </div >
                                </Box>
                            </Flex>
                        </Box>
                    </Box>

                </form >
            }
        </Container>

    )
}

export default WithDefaultContent(SiparisFrm)


