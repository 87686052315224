import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import Blog3 from '@solid-ui-blocks/Blog/Block03'
import httpService from '../../service/httpService';
import loadsGif from '../assets/loads.gif'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'
import SiparisFrm from '@solid-ui-blocks/Siparis/SiparisFormu'

const SiparisPage = props => {


  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const [indId, setindId] = useState("");
  const [sipId, setsipId] = useState("");

  const [ldg, setLdg] = useState(false);


  useState(() => {
    if (typeof window !== 'undefined') {

     if (window.location.search.includes("I="))
     {
      setsipId(window.location.search.replace("?I=", ""));
     } else {
      window.location.assign("/");
    }
  
      setLdg(true);
    }
  }, []);




  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Sipariş' />
      <Divider space='5' />
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="die-cut order" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        // <Blog3 id={`blgbicak`} content={{ Id: "53" }} benzerModelList={{ benzerModelList: benzerModelList }} />
        <SiparisFrm content={{ SiparisId:sipId }} ></SiparisFrm>
      }
         <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />  
       <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageSiparisPage { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default SiparisPage
