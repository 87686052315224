import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import ReactModal from 'react-modal'
import { FaRegStar, FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import Slider from '@solid-ui-blocks/Slider/Block01'
import loadingGif from '../../../assets/model-cizim-kutu-tasarimi2.gif'
import parse from 'html-react-parser'
import { setKtTokenSifre, getKtLogin, logOut ,getKtToken} from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import loadsBeyazGif from '../../../assets/loadsBeyaz.gif'
import indirPng from '../../../assets/online-paskage-pdf-die-cut.png'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import { ModalContext } from '@solid-ui-components/Modal'
// import indirmeCss from './_bicakizi.css' //style={indirmeCss}
import Tabs from '@solid-ui-components/Tabs'
// import { Tab } from 'react-tabs'
import { TabsContext } from '@solid-ui-components/Tabs'
import { AiOutlineCloseCircle, AiFillCloseSquare, AiOutlineDelete } from "react-icons/ai";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion'
import Icon from '@solid-ui-components/ContentIcon'
import Reveal from '@solid-ui-components/Reveal'
import toast, { Toaster } from 'react-hot-toast'
import './styles.css'
import config from '../../../../../../site/src/core/config'


const styles = {

    modalContent2: {
        borderRadius: `lg`,
        bg: `contentBg`,
        maxWidth: `container`,
        overflow: `hidden`,
        py: 4,
        px: 4
    },
    close: {
        position: `absolute`,
        size: [`icon.sm`, null, `icon.md`],
        zIndex: 99,
        width: 50,
        height: 50

    },

}

const FileEdit = ({ ...props }) => {

    const {

        addEventOpen,
        handlePopup,
        detayName,
        mesaj,
        listRefreshEvent,
        lnk,

    } = props


    const formId = "frmFile"
    const { handleSubmit, submitting, success } = useForm()
    const { formValues, setFormValues } = useContext(FormContext)
    const { setActiveModal } = useContext(ModalContext)

    const [firtProcess, setfirtProcess] = useState("1");


    const {
        token
    } = getKtToken()


    return (

        <ReactModal
            id={"kaliteModals"}
            isOpen={addEventOpen}
            onRequestClose={() => handlePopup(false)}
            closeTimeoutMS={300}
            className='CustomModalPortalContent'
            overlayClassName='ModalPortalOverlay'
            shouldFocusAfterRender={false}
            sx={css({ ...styles.modalContent2 })}
            {...props}
        >
            <Flex
                sx={{
                    alignItems: `center`,
                    flexDirection: [
                        `column`,
                        `row`
                    ]
                }}
            >
                <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
                    <Box sx={{ textAlign: `center` }}>
                        <ContentText content={{
                            text: detayName,
                            color: "omegaDarker",
                            variant: "h5",
                            space: 2
                        }} />
                        <Box sx={{ textAlign: `center` }}>
                            <ContentText content={{
                                text: mesaj,
                                color: "omegaDark",
                            }} />
                        </Box>                   
                    </Box>
                    <Box>
                        <Divider space={1} />
                        <Flex
                            sx={{
                                alignItems: `center`,
                                flexDirection: [
                                    `column`,
                                    `row`
                                ]
                            }}
                        >
                            <Box
                                sx={{
                                    display: [`none`, null, `block`],
                                    flex: 1,
                                    p: '0.9rem',
                                    borderRadius: `left`,
                                    overflow: `hidden`
                                }}
                            >
                                <div style={{ height: "280px", width: "100%" }}>
                                    <iframe style= {{borderStyle:'none', width: '100%',height:'280px'  }} src={config.serviceBaseAddress + "Upl.aspx?T=" + token + lnk} ></iframe>
                                </div>
                            </Box>

                        </Flex>


                    </Box>

                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                        <div className="popup-btns">
                             <Button type="submit"
                                aria-label="Popup kapat"
                                variant="dark"

                                id={"btnGeriDon"}
                                onClick={e => {
                                    handlePopup();
                                    listRefreshEvent();
                                }}
                            >
                                Kapat
                            </Button>
                          

                        </div>
                    </Box>

                </Box>

            </Flex>

        </ReactModal>
    )
}


export default WithDefaultContent(FileEdit)


